<template>
  <footer class="site-footer">
    <div class="footer-content">
      <div class="footer-links">
        <router-link to="/about?tab=about">关于我们</router-link>
        <router-link to="/about?tab=contact">联系我们</router-link>
        <router-link to="/about?tab=sitemap">网站地图</router-link>
        <router-link to="/about?tab=legal">法律声明</router-link>
        <router-link to="/about?tab=review">内容审核</router-link>
        <router-link to="/about?tab=sitemap">网站统计</router-link>
      </div>
      <div class="footer-info">
        <p>京公网安备11010502051471号 统一社会信用代码：911101056883900189
          北京书香文雅图书文化有限公司 地址：北京市石景山区石景山路68号金安桥1号楼二层222 电话：010-56026288 手机：13021225666</p>
        <p>Copyright © 2025 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19058735号-2</a> 增值电信业务经营许可证 京B2-20230383</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.site-footer {
  background-color: #f8f8f8;
  padding: 20px 0;
  font-size: 14px;
  color: #666;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-links {
  margin-bottom: 15px;
  font-size: 12px;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links a:hover {
  color: #ffa500;
}

.footer-info p {
  margin: 5px 0;
  font-size: 12px;
}
</style>