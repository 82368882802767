
<template>
  <div class="user-center-layout">
    <UserSidebar />
    <div class="main-content">
      <div class="header">
        <h2>个人资料</h2>
      </div>
      <div class="actions myform">
        <div class="left">
          <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="account">用户名:</label>
            <div class="input-wrapper">
              <input type="text" id="account" v-model="userInfo.account">
              <p class="hint">用户名可作登陆名只能修改一次,请谨慎修改</p>
            </div>
          </div>
          <div class="form-group">
            <label for="nickname">昵称/笔名:</label>
            <div class="input-wrapper">
              <input type="text" id="nickname" v-model="userInfo.nickname">
            </div>
          </div>
          <div class="form-group">
            <label for="qq">QQ:</label>
            <div class="input-wrapper">
              <input type="text" id="qq" v-model="userInfo.qq">
            </div>
          </div>
          <div class="form-group">
            <label for="email">邮箱:</label>
            <div class="input-wrapper">
              <input type="email" id="email" v-model="userInfo.email">
            </div>
          </div>
          <div class="form-group">
            <label for="descr">自我描述:</label>
            <div class="input-wrapper">
              <textarea id="descr" v-model="userInfo.descr" rows="4"></textarea>
              <p class="hint">向他人介绍下自己吧,别超过100字哦</p>
            </div>
          </div>
          <div class="form-group submit-group">
            <label></label>
            <div class="input-wrapper">
              <button type="submit" class="submit-button">保存资料</button>
            </div>
          </div>
        </form>
        </div>
        <div class="right">
          <div class="avatar-container">
            <img :src="avatarUrl" alt="用户头像" class="avatar-preview">
            <el-button @click="showCropper = true" class="upload-btn">修改头像</el-button>
          </div>
        </div>
      </div>
    </div>
        <!-- 图片裁剪对话框 -->
        <el-dialog title="裁剪头像" :visible.sync="showCropper" width="600px">
          <VueCropper
            ref="cropper"
            :img="imageSrc"
            :outputSize="1"
            :outputType="'png'"
            :info="true"
            :full="true"
            :canMove="false"
            :canMoveBox="false"
            :original="false"
            :autoCrop="true"
            :autoCropWidth="200"
            :autoCropHeight="200"
            :fixedBox="true"
          ></VueCropper>
          <span slot="footer" class="dialog-footer">
            <el-button @click="showCropper = false">取 消</el-button>
            <el-button type="primary" @click="cropImage">确 定</el-button>
          </span>
  </el-dialog>
  </div>
</template>

<script>
import UserSidebar from '@/components/UserSidebar.vue';
import apiService from '@/api/api';
import {VueCropper} from 'vue-cropper';

export default {
  name: 'UpdateUserInfo',
  components: {
    UserSidebar,
    VueCropper,
  },
  data() {
    return {
      userInfo: {
        account: '',
        nickname: '',
        qq: '',
        email: '',
        descr: '',
      },
      avatarUrl: '@/assets/default-avatar.png', // 默认头像
      showCropper: false,
      imageSrc: '',
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      apiService.getUserInfo().then(res => {
        this.userInfo = res.data.data;
      });
    },
    submitForm() {
        console.log(this.userInfo)
      apiService.updateUserInfo(this.userInfo).then(res => {
        if (res.data.code==0) {
         this.$notify({
          title: '成功',
          message: '个人资料更新成功',
          type: 'success'
         });
        } else {
          this.$notify({
          title: '失败',
          message: '个人资料更新失败，请重试',
          type: 'error'
         });
        }
      }).catch(error => {
        console.error('更新个人资料时出错:', error);
        alert('更新个人资料时出错，请重试');
      });
    },
        // 裁剪图片
        cropImage() {
  this.$refs.cropper.getCropBlob((blob) => {
    const formData = new FormData();
    formData.append('file', blob, 'avatar.png');
    
    apiService.uploadAvatar(formData).then(res => {
      if (res.data.code === 0) {
        this.userInfo.avatarUrl = res.data.data.url;
        this.$notify({
          title: '成功',
          message: '头像上传成功',
          type: 'success'
        });
      } else {
        this.$notify({
          title: '失败',
          message: '头像上传失败，请重试',
          type: 'error'
        });
      }
      this.showCropper = false;
    }).catch(error => {
      console.error('上传头像时出错:', error);
      this.$notify({
        title: '错误',
        message: '上传头像时出错，请重试',
        type: 'error'
      });
      this.showCropper = false;
    });
  });
},
selectImage() {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.onchange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageSrc = event.target.result;
        this.showCropper = true;
      };
      reader.readAsDataURL(file);
    }
  };
  input.click();
},
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';

.myform {
  padding-top: 20px;
}
.right{
  display: none;
}

.form-group {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start;
  font-size: 14px;
}

label {
  width: 100px;
  text-align: right;
  padding-right: 15px;
  line-height: 34px;
  color: #333;
  font-size: 12px;
}

.input-wrapper {
  flex: 1;
}

input[type="text"],
input[type="email"],
textarea,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

input[disabled] {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.hint {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}


.submit-button {
  background-color: #ffa500;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.submit-button:hover {
  background-color: #ffa500;
}

.submit-group {
  margin-top: 20px;
}
</style>