<template>
  <div>
    <div class="user-info">
      <div class="info-item">
        <span class="label">用户名：</span>
        <span class="value">{{ chargeProducts.unichen }}</span>
      </div>
      <div class="info-item">
        <span class="label">金币：({{ chargeProducts.ucion }})</span>
      </div>
      <div class="info-item">
        <span class="label">月票：({{ chargeProducts.ticket }})</span>
      </div>
    </div>
    <el-tabs v-model="activeTab"  @tab-click="tabChange">
      <el-tab-pane label="充值金币" name="coins">
        <!-- 充值金币的内容 -->
      <div class="charge-options">
        <h3 class="title">
            <i></i>购买项目
            <span>（充值比例：1元=10金币，充值数量：必须是10的整数倍）</span>
        </h3>
          <ul class="product-list">
            <li class="product" v-for="(product, index) in chargeProducts.pay.cion" :key="index" @click="selectProduct(index)" :class="{ 'active': selectedProductIndex === index }">
                <p class="product-name">{{ product.cion }}金币</p>
                <p class="product-price">¥{{ product.rmb }}</p>
                <i></i>
            </li>
            <li class="product" @click="selectCustomAmount" :class="{ 'active': isCustomAmountSelected }" >
                <input v-model="customAmount"  placeholder="其他金额" class="custom-input"  @input="updateCustomAmount">
            </li>
          </ul>
      </div>
      <div class="charge-options">
        <h3 class="title">
            <i></i>支付方式
        </h3>
          <ul class="product-list">
            <li class="product active pay-way">
                <div class="pay-icon"><img src="@/assets/wechat.png" alt="微信支付图标"></div>
                <div class="pay-name"><p class="product-name">微信支付</p></div>
            </li>
          </ul>
      </div>
      <div class="charge-options pay-container">
            <div class="payment-info">
                <div class="amount-section">
                    <h3 class="title">
                        <i></i>应付金额
                    </h3>
                    <p class="should-payment">{{ selectedAmount }}元</p>
                </div>
                <div class="pay-button-section">
                    <button class="pay-button" @click="buyProduct">确认支付</button>
                </div>
            </div>
            <div class="qr-code-section" v-if="showQrCode">
              <img ref="qrCodeImage" :src="qrCodeImage" alt="支付二维码">
            </div>
      </div>
      </el-tab-pane>
      <el-tab-pane label="购买月票"  name="ticket">
        <!-- 购买月票的内容 -->
        <div class="charge-options">
        <h3 class="title">
            <i></i>购买项目
            <span>（购买比例：1元=10金币）</span>
        </h3>
          <ul class="product-list">
            <li class="product" v-for="(ticket, index) in chargeProducts.pay.ticket" :key="index" @click="selectTicket(index)" :class="{ 'active': selectedTicketIndex === index }">
                <p class="product-name">{{ ticket.num }}张月票</p>
                <p class="product-price">¥{{ ticket.rmb }}</p>
                <i></i>
            </li>
            <li class="product" @click="selectCustomTicketAmount" :class="{ 'active': isCustomTicketAmountSelected }">
                <input v-model="customTicketAmount" placeholder="其他数量" class="custom-input" @input="updateCustomTicketAmount">
            </li>
          </ul>
      </div>
      <div class="charge-options">
        <h3 class="title">
            <i></i>支付方式
        </h3>
          <ul class="product-list">
            <li class="product  pay-way"  @click="selectPaymethod(1)" :class="{ 'active': paymethodSelect === 1 }">
                <div class="pay-icon"><img src="@/assets/money.png" alt="微信支付图标"></div>
                <div class="pay-name"><p class="product-name">金币支付</p></div>
            </li>
            <li class="product  pay-way" @click="selectPaymethod(0)" :class="{ 'active': paymethodSelect === 0 }">
                <div class="pay-icon"><img src="@/assets/wechat.png" alt="微信支付图标"></div>
                <div class="pay-name"><p class="product-name">微信支付</p></div>
            </li>
          </ul>
      </div>
      <div class="charge-options pay-container">
            <div class="payment-info">
                <div class="amount-section">
                    <h3 class="title">
                        <i></i>应付金额
                    </h3>
                    <p class="should-payment">{{ selectedTicketAmount }}元</p>
                </div>
                <div class="pay-button-section">
                    <button class="pay-button" @click="buyTicket">确认支付</button>
                </div>
            </div>
            <div class="qr-code-section" v-if="showQrCodeTicket">
              <img ref="ticketQrCodeImage" :src="ticketQrCodeImage" alt="支付二维码">
            </div>
      </div>
      </el-tab-pane>
      <el-tab-pane label="购买VIP" name="vip">
        <!-- 购买VIP的内容 -->
        <div class="charge-options">
        <h3 class="title">
            <i></i>购买项目
            <span>（购买比例：1元=10金币）</span>
        </h3>
          <ul class="product-list">
            <li class="product" v-for="(vip, index) in chargeProducts.pay.vip" :key="index" @click="selectVip(index)" :class="{ 'active': selectedVipIndex === index }">
                <p class="product-name">{{ vip.name }}</p>
                <p class="product-price">¥{{ vip.rmb }}</p>
                <p class="product-price">{{ vip.txt }}</p>

                <i></i>
            </li>
          </ul>
      </div>
      <div class="charge-options">
        <h3 class="title">
            <i></i>支付方式
        </h3>
          <ul class="product-list">
            <li class="product  pay-way"  @click="selectPaymethod(1)" :class="{ 'active': paymethodSelect === 1 }">
                <div class="pay-icon"><img src="@/assets/money.png" alt="微信支付图标"></div>
                <div class="pay-name"><p class="product-name">金币支付</p></div>
            </li>
            <li class="product  pay-way" @click="selectPaymethod(0)" :class="{ 'active': paymethodSelect === 0 }">
                <div class="pay-icon"><img src="@/assets/wechat.png" alt="微信支付图标"></div>
                <div class="pay-name"><p class="product-name">微信支付</p></div>
            </li>
          </ul>
      </div>
      <div class="charge-options pay-container">
            <div class="payment-info">
                <div class="amount-section">
                    <h3 class="title">
                        <i></i>应付金额
                    </h3>
                    <p class="should-payment">{{ selectedVipAmount }}元</p>
                </div>
                <div class="pay-button-section">
                    <button class="pay-button" @click="buyVip">确认支付</button>
                </div>
            </div>
            <div class="qr-code-section" v-if="showQrCodeVip">
              <img ref="vipQrCodeImage" :src="vipQrCodeImage" alt="支付二维码">
            </div>
      </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { apiService } from '@/api/api';
import QRCode from 'qrcode';

export default {
  name: 'BuyService',
  props: {
    initialTab: {
      type: String,
      default: 'coins'
    }
  },
  data() {
    return {
      activeTab: this.initialTab,
      chargeProducts: {
        unichen: '',
        ucion: 0,
        ticket: 0,
        pay: {
        cion: []
        }
      },
      selectedProduct: null,
      customAmount: null,
      customTicketAmount: null,
      isCustomAmountSelected: false,
      isCustomTicketAmountSelected: false,
      selectedProductIndex: null,
      selectedTicketIndex: null,
      selectedVipIndex: null,
      selectedAmount: 0,
      selectedTicketAmount: 0,
      selectedVipAmount: 0,
      qrCodeImage: '',
      showQrCode:false,
      showQrCodeTicket:false,
      showQrCodeVip:false,
      ticketQrCodeImage: '',
      vipQrCodeImage: '',
      paymethodSelect: 0,
    };
  },
  mounted() {
    this.getChargeProducts();
    this.activateInitialTab();
    this.getUserInfo();
  },
  methods: {
    getUserInfo(){
      apiService.getUserInfo().then(res => {
        if(res.data.code == 0){
          let resData = res.data.data;
          this.chargeProducts.unichen = resData.nickname;
          this.chargeProducts.ucion = resData.vo.gold;
          this.chargeProducts.ticket = resData.vo.ticket;
        }
      });
    },
    activateInitialTab() {
      if (['coins', 'ticket', 'vip'].includes(this.initialTab)) {
        this.activeTab = this.initialTab;
      }
    },
    tabChange(tab){
      //重置支付方式
      this.paymethodSelect = 0;
      //重置支付二维码
      this.qrCodeImage = '';
      this.ticketQrCodeImage = '';
      this.vipQrCodeImage = '';
      //重置支付金额
      this.selectedAmount = 0;
      this.selectedTicketAmount = 0;
      this.selectedVipAmount = 0;
      //重置支付项目
      this.selectedProductIndex = null;
      this.selectedTicketIndex = null;
      this.selectedVipIndex = null;
      //重置自定义输入
      this.customAmount = null;
      this.customTicketAmount = null;
      this.customVipAmount = null;

      this.activeTab = tab.name;
    },
    async generateQRCode(qrCodeUrl,qrcodeShowSrc) {
      try {
        const qrCodeDataUrl = await QRCode.toDataURL(qrCodeUrl)
        if(qrcodeShowSrc == 'coin'){  
          this.qrCodeImage = qrCodeDataUrl
          this.showQrCode = true;
        }else if(qrcodeShowSrc == 'ticket'){
          this.ticketQrCodeImage = qrCodeDataUrl
          this.showQrCodeTicket = true;
        }else if(qrcodeShowSrc == 'vip'){
          this.vipQrCodeImage = qrCodeDataUrl
          this.showQrCodeVip = true;
        }
      } catch (error) {
        console.error('生成二维码时出错:', error)
      }
    },
    buyProduct(){
      let good = null;
      if (this.selectedProductIndex !== null) {
        good = JSON.stringify(this.chargeProducts.pay.cion[this.selectedProductIndex]);
      } else {
        good = JSON.stringify({})
      }
      let params = {
        goodsJson:good,
        type:1,
        price:this.selectedAmount,
      }
      let str = "购买了"+this.selectedAmount * 10+"金币";

      this.buyApi(params,'coin',str);
    },
    buyTicket(){
      let good = null;
      if (this.selectedTicketIndex !== null) {
        good = JSON.stringify(this.chargeProducts.pay.ticket[this.selectedTicketIndex]);
      } else {
        good = JSON.stringify({})
      }
      let params = {
        goodsJson:good,
        type:2,
        price:this.selectedTicketAmount,
      }
      let str = "购买了"+this.selectedTicketAmount+"张月票";
      this.buyApi(params,'ticket',str);
    },
    buyVip(){
      let good = JSON.stringify(this.chargeProducts.pay.vip[this.selectedVipIndex]);
      let params = {
        goodsJson:good,
        type:3,
        price:this.selectedVipAmount,
      }
      let strData =this.chargeProducts.pay.vip[this.selectedVipIndex];
      let str = "购买了"+strData.name+','+strData.txt;
      this.buyApi(params,'vip',str);
    },
    buyApi(params,qrcodeShowSrc,desc){
      apiService.createOrder(params).then(res => {
        if(res.data.code == 0 ){
            //发起支付请求
            let data = {
              id:res.data.data.id,
              body:desc
            }
            apiService.payOrder(data).then(response =>{
                if(response.data.code == 0){
                    this.generateQRCode(response.data.data.codeUrl,qrcodeShowSrc);
                }else{
                    this.$notify({
                      title: '失败',
                      message: response.data.msg,
                      type: 'error'
                    });
                }
            })
        }else{
            alert(res.data.msg);
        }
      });
    },
    fetchOrderStatus(orderId){
      //轮询，每秒查询接口检测支付是否完成，完成的话弹窗以及关闭二维码显示
      //设置检查次数设定，如果超过次数，则弹窗提示支付失败
      let checkCount = 0;
      const maxCheckCount = 300; // 设置最大检查次数，例如30次
      const checkInterval = 1000; // 每次检查间隔1秒

      const checkPayStatus = () => {
        apiService.checkPayStatus(orderId).then(res => {
          if (res.data.code === 0) {
            if (res.data.data.status) {
              this.showQrCode = false;
              this.showQrCodeTicket = false;
              this.showQrCodeVip = false;
              this.qrCodeImage = '';
              this.ticketQrCodeImage = '';
              this.vipQrCodeImage = '';
              this.$notify({
                title: '成功',
                message: '支付成功',
                type: 'success'
              });
            } else {
              checkCount++;
              if (checkCount < maxCheckCount) {
                setTimeout(checkPayStatus, checkInterval);
              } else {
                this.$notify({
                  title: '失败',
                  message: '支付超时，请重试',
                  type: 'error'
                });
              }
            }
          } else {
            this.$notify({
              title: '错误',
              message: '检查支付状态失败',
              type: 'error'
            });
          }
        }).catch(error => {
          console.log(error);
          this.$notify({
            title: '错误',
            message: '检查支付状态出错',
            type: 'error'
          });
        });
      };

      checkPayStatus();
    },
    selectProduct(index) {
      this.selectedProductIndex = index;
      this.isCustomAmountSelected = false;
      this.selectedAmount = this.chargeProducts.pay.cion[index].rmb;
    },
    selectCustomAmount() {
       this.isCustomAmountSelected = true;
      this.selectedProductIndex = -1;
      this.updateCustomAmount();
    },
    updateCustomAmount(){
        this.selectedAmount = this.customAmount ? parseFloat(this.customAmount) : 0;
    },
    selectTicket(index) {
      this.selectedTicketIndex = index;
      this.isCustomTicketAmountSelected = false;
      this.selectedTicketAmount = this.chargeProducts.pay.ticket[index].rmb;
    },
    selectCustomTicketAmount() {
      this.isCustomTicketAmountSelected = true;
      this.selectedTicketIndex = -1;

      this.updateCustomTicketAmount();
    },
    updateCustomTicketAmount(){
      this.selectedTicketAmount = this.customTicketAmount ? parseFloat(this.customTicketAmount) : 0;
    },
    selectVip(index) {
      this.selectedVipIndex = index;
      this.isCustomVipAmountSelected = false;
      this.selectedVipAmount = this.chargeProducts.pay.vip[index].rmb;
    },
    selectPaymethod(index){
      this.paymethodSelect = index;
    },
    async getChargeProducts() {
      await apiService.getChargeProductList()
        .then(response => {
          if (response.data.code === 0) {
            this.chargeProducts = response.data.data;
          } else {
            console.error('获取充值商品列表失败:', response.data.msg);
          }
        })
        .catch(error => {
          console.error('获取充值商品列表出错:', error);
        });
    },
  }
};
</script>

<style>
.user-info {
  display: flex;
}
.info-item{
  padding: 10px;
}

.title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #999;
    height: 20px;
    line-height: 20px;
}
.title span {
    margin-left: 10px;
    font-size: 12px;
}
.product-list {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    list-style: none;
    padding-left: 30px;
}
.product {
    margin-right: 15px;
    margin-bottom: 8px;
    width: 120px;
    min-height: 50px;
    padding-top: 10px;
    min-height: 50px;
    font-size: 15px;
    color: #ff620e;
    border: 1px solid #ccc;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}


.product:hover {
    border: 1px solid #ff620e;
}

.active {
    border: 1px solid #ff620e;
}

.product-price{
    font-size: 12px;
    padding: 5px;
    margin: 0;
}

.product-name {
    padding: 5px;
    margin: 0;
    font-size: 15px;
}

.pay-way{
    display: flex;
    align-items: center;
    justify-content: center;
}

.pay-icon{
    width: 30px;
    height: 30px;
}

.pay-icon img{
    width: 100%;
    height: 100%;
}

.custom-input {
    height: 89%;
    border: none;
    width: 82%;
    margin: 0;
    outline: none;
    color: #ff620e;
}

.pay-container {
    display: flex;
    padding-left: 30px;
    align-items: center;
}
.payment-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.amount-section {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.should-payment{
    font-size: 20px;
    color: #ff620e;
    font-weight: bold;
    padding-left: 20px;
}
.qr-code-section {
    padding-left: 60px;
}

.pay-button {
    background-color: #ff620e;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 120px;
}

.el-tabs__item.is-active {
  color: orange !important;
}
.el-tabs__active-bar {
  background-color: orange !important;
}
</style>