<template>
  <div class="user-center-layout">
    <AuthorSidebar />
    <div class="main-content">
      <div class="header-info">
        <div>
          <img src="@/assets/default-avatar.png" alt="作者仪表盘">
        </div>
        <div>
          <p>{{ userInfo.account }}</p>
          <p>笔名：{{ userInfo.nickname }}</p>
          <p>收入：{{ userInfo.qq }}元</p>
        </div>
      </div>
      <div class="dashboard-content">
        <section class="manga-section">
          <div class="manga-section-header">
            <h3>我的作品</h3>
            <!-- <router-link :to="{ name: 'AuthorWorks' }" class="more-link">查看全部</router-link> -->
          </div>
          <div class="manga-grid">
            <!-- 这里可以循环展示作者的作品 -->
            <div class="manga-item" v-for="manga in authorWorks" :key="manga.id">
              <img :src="manga.cover" :alt="manga.title">
              <h4>{{ manga.name }}</h4>
              <p>{{ manga.descr.substring(0, 10) }}{{ manga.descr.length > 10 ? '...' : '' }}</p>
              <span class="rating">更新至：{{ manga.dyNews }}</span>
            </div>
          </div>
        </section>

        <section class="stats-section">
          <div class="manga-section-header">
            <h3>数据统计</h3>
          </div>
          <div class="stats-grid">
            <div class="stats-item">
              <h5>总阅读量</h5>
              <p>{{ totalReads }}</p>
            </div>
            <div class="stats-item">
              <h5>总收藏数</h5>
              <p>{{ totalFavorites }}</p>
            </div>
            <div class="stats-item">
              <h5>本月收入</h5>
              <p>{{ monthlyIncome }}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorSidebar from '@/components/AuthorSidebar.vue'
import apiService from '@/api/api';


export default {
  name: 'AuthorDashboard',
  components: {
    AuthorSidebar
  },
  data() {
    return {
      authorWorks: [],
      totalReads: 0,
      totalFavorites: 0,
      monthlyIncome: 0,
      userInfo: {}
    }
  },
  mounted() {
    this.fetchAuthorData()
    this.getUserInfo()
  },
  methods: {
    fetchAuthorData() {
      // 这里应该调用API获取作者数据
      // 以下是示例数据
      this.authorWorks = [
        { id: 1, name: '作品1', cover: 'path/to/cover1.jpg', descr: '作品1的描述', dyNews: '第10话' },
        { id: 2, name: '作品2', cover: 'path/to/cover2.jpg', descr: '作品2的描述', dyNews: '第5话' },
      ]
      this.totalReads = 10000
      this.totalFavorites = 500
      this.monthlyIncome = '¥1000'
    },
    getUserInfo() {
      apiService.getUserInfo().then(response => {
        if (response.data.code == 0) {
          this.userInfo = response.data.data;
        }
      });
    }
  }
}
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';

.header-info {
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  padding: 10px 20px;
}

.header-info h4 {
  margin-bottom: 10px;
  color: #333;
  font-size: 18px;
}

.dashboard-content {
  background-color: #fff;
  padding: 20px;
}

.manga-section, .stats-section {
  margin-bottom: 40px;
}

.manga-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

.manga-section-header h3 {
  font-size: 16px;
  color: #333;
}

.more-link {
  font-size: 12px;
  color: #666;
  text-decoration: none;
}

.manga-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.manga-item {
  text-align: start;
  font-size: 12px;
}

.manga-item img {
  width: 100%;
  height: auto;
}

.manga-item h4 {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 5px;
}

.manga-item p {
  color: #666;
  margin-bottom: 5px;
}

.manga-item .rating {
  font-size: 10px;
  background-color: #ffa500;
  padding: 2px 5px;
  border-radius: 3px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.stats-item {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
}

.stats-item h5 {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.stats-item p {
  font-size: 18px;
  color: #ffa500;
  font-weight: bold;
}
</style>