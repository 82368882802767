<template>
  <el-dialog
    title="登录"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
      <el-form-item prop="username" label="用户名">
        <el-input v-model="loginForm.username"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input type="password" v-model="loginForm.password"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleLogin" class="login-btn">登录</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      }
    }
  },
  methods: {
    ...mapActions(['login']),
    handleClose() {
      this.$refs.loginForm.resetFields();
      this.dialogVisible = false;
    },
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          try {
            await this.login(this.loginForm);
            this.$notify({
              title: '成功',
              message: '登录成功',
              type: 'success'
            });
            this.handleClose();
            this.$emit('login-success');
          } catch (error) {
            this.$notify({
              title: '失败',
              message: '登录失败: ' + error.message,
              type: 'error'
            });
          }
        }
      });
    }
  }
}
</script>
<style scoped>
.error-message {
    color: #ff4d4f; /* 警告色 */
    font-size: 14px;
    margin-top: 5px;
    display: block;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
}

.forget-password {
  color: #666;
  text-decoration: none;
}

.login-btn {

  background-color: #ffa500;
  color: white;
  border: none;
  border-radius: 3px;
}

.login-btn:hover {
  background-color: #ff5252;
}

.register-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.register-link a {
  color: #ffa500;
  text-decoration: none;
}
</style>